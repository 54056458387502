import React from 'react'
import './Footer.css'

import { ReactComponent as FacebookIcon } from './icon-facebook.svg'
import { ReactComponent as TiktokIcon } from './icon-tiktok.svg'
import { ReactComponent as InstagramIcon } from './icon-instagram.svg'

export default function Footer() {
  return (
    <footer>
      <a
        href='https://www.facebook.com/usuhanofficial'
        target='_blank'
        rel='noreferrer'
      >
        <FacebookIcon />
      </a>
      <a
        href='https://www.tiktok.com/@usuhanofficial'
        target='_blank'
        rel='noreferrer'
      >
        <TiktokIcon />
      </a>
      <a
        href='https://www.instagram.com/usuhanofficial/'
        target='_blank'
        rel='noreferrer'
      >
        <InstagramIcon />
      </a>
    </footer>
  )
}
