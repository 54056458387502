import './App.css'

import Background from './background/Background'
import Timer from './timer/Timer'
import Footer from './footer/Footer'
import Logo from '../asset/logo.png'
function App() {
  return (
    <div className='App'>
      <Background />
      <main className='main'>
        <p className='main__text'>We're coming soon</p>
        <Timer />
        <img src={Logo} className='logo' alt='Usuhan — Nothing is Impossible' />
      </main>
      <Footer />
    </div>
  )
}

export default App
